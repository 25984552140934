import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {
  ClockIcon,
  EmojiHappyIcon,
  RefreshIcon,
  GiftIcon,
  TrendingUpIcon,
  CashIcon,
  UserGroupIcon,
  LockClosedIcon,
  ChevronDownIcon
} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"

const features = [
  {
    name: 'Important Dates',
    description: 'You will be able to enter the giveaway on this page on Friday, 27th August at 18:00 UTC. The exclusive MoonDash x DOGE NFT will also premiere at the same time. The giveaway will run for 1 week exactly and winners will be announced shortly after 18:00 UTC on 3rd September.',
    icon: ClockIcon,
  },
  {
    name: 'Entry cost',
    description: 'To enter, you must simply hold the required amount of MoonDash tokens. There will be 3 tiers for entries. Hold at least 25,000,000 (25 Million) to get 1 entry. Hold at least 225,000,000 (225 Million) to get 10 entries. Hold at least 2,000,000,000 (2 Billion) to get 100 entries. You will also be able to get entries for free by completing various social sharing/upvoting tasks.',
    icon: CashIcon,
  },
  {
    name: 'How much can you win?',
    description: 'We\'ll be picking a number of winners to share the 7,500 DOGE winnings pot. There will be 5 winners who will share 500 DOGE each and 1 super lucky winner who will get the grand prize of 5,000 DOGE! We\'ll also be giving out our exclusive rare MoonDash x DOGE NFT to 1 lucky winner!',
    icon: GiftIcon,
  },
  {
    name: 'Lots of Happy Winners',
    description: 'When we launched MoonDash, we ran BNB giveaways and have given out over $20,000 worth of tokens to date! We\'ve now decided to branch out and run more amazing giveaway events where we collaborate with other exciting tokens to reward both communities. MoonDash x DOGE is our first such giveaway event and there will be many more to come!',
    icon: EmojiHappyIcon,
  },

]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const GamePage = () => {
  
  return (
    <Layout>
      <Seo title="Game" />
      
      <div className="text-white">
        <div className="container md:grid grid-cols-1 sm:grid-cols-12 gap-4 sm:gap-12 md:gap-16 lg:gap-20  py-10 md:py-24 lg:py-36 xl:py-40 relative">
        <div className="circle absolute z-0 -top-48 md:-top-48 lg:-top-96 -right-48 lg:-right-64"></div>
        <div className="col-span-2 lg:py-8 w-full">
            <div className="relative flex h-full md:flex-col justify-between">

            <StaticImage
                src="../images/game-elon.png"
                className="z-20 w-28 lg:w-full lg:max-w-sm"
                width={384}
                alt=""
                objectFit="contain"
              />
              <StaticImage
                src="../images/game-roadster.png"
                className="z-20 w-28 lg:w-full lg:max-w-sm"
                width={384}
                alt=""
                objectFit="contain"
              />

            </div>

          </div>
          <div className="col-span-8 relative z-30 flex flex-col items-center text-center py-6 md:py-0">
            <h2 className="text-base md:text-lg lg:text-xl font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block mb-6">Launching Q4 2021</h2>
            <h1 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-display font-bold mb-4 lg:mb-6 xl:mb-8">
              Upcoming MoonDash pixel game</h1>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl xl:leading-relaxed text-gray-200">Strap in and get your rocket ready for take-off! You'll find many exciting things on your way to the moon, including rare NFTs, MoonDash tokens, Giveaway entries & more. Upgrade and customize your rocket to move even faster and stand out from the crowd!</p>
          </div>

          <div className="col-span-2 lg:py-8 w-full">
            <div className="relative flex h-full md:flex-col justify-between">
            <StaticImage
                src="../images/game-spaceship.png"
                className="z-20 w-28 lg:w-full lg:max-w-sm"
                width={384}
                alt=""
                objectFit="contain"
              />
              <StaticImage
                src="../images/game-doge.png"
                className="z-20 w-28 lg:w-full lg:max-w-sm"
                width={384}
                alt=""
                objectFit="contain"
              />

            </div>

          </div>

        </div>
      </div>



    </Layout>
  )
}

export default GamePage

